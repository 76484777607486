<template>
  <div>
    <el-form>
      <el-row :gutter="20">
        <el-col :span="4">
          <div class="form-group">
            <el-form-item label="Label">
              <el-input
                v-model="field.label"
                placeholder="Enter Label"
              ></el-input>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="form-group">
            <el-form-item label="Function">
              <el-select
                v-model="field.function_type"
                placeholder="Select Function Type"
                @change="onChangeFunctionType"
              >
                <el-option
                  v-for="item in functionTypes"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>

        <el-col :span="4">
          <div class="form-group">
            <el-form-item label="Fields Type">
              <el-select
                placeholder="Select Field Type"
                v-model="field.fields_type"
              >
                <el-option
                  v-for="(item, index) in fields_types"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>

        <el-col :span="4">
          <div class="form-group">
            <el-form-item label="Template">
              <el-select
                v-model="field.repeatable_template"
                placeholder="Select Template"
                @change="onChangeTemplate"
              >
                <el-option
                  key="self"
                  label="Self Template"
                  value="self"
                ></el-option>
                <el-option
                  v-for="(item, index) in repeatableTemplates"
                  v-bind:key="index"
                  :label="item.label"
                  :value="item.action_button_repeating_template"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>

        <el-col :span="4">
          <div class="form-group">
            <el-form-item label="Fields" v-if="!field.aggregate_self_template">
              <el-select
                v-loading="loadingFields"
                v-model="field.aggregation_field"
                placeholder="Select field"
                :disabled="!field.repeatable_template"
              >
                <el-option
                  v-for="item in fields"
                  :key="item._id"
                  :label="item.label"
                  :value="item._id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Fields" v-if="field.aggregate_self_template">
              <el-select
                v-loading="loadingFields"
                v-model="selectedField"
                placeholder="Select field"
                @change="onSelectField"
                :disabled="!field.repeatable_template"
              >
                <el-option
                  v-for="item in fields"
                  :key="item.key"
                  :label="item.label"
                  :value="item.key"
                  :disabled="
                    item.data_table_field_index > -1 &&
                    field.fields_type == 'INDIVIDUAL'
                  "
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <h4>Equation - {{ field.function_type + " : " }}</h4>

          <hr />
          <!-- {{getEquation}} -->
          <el-tag
            v-for="(field, index) of field.selected_fields"
            :key="index"
            closable
            @close="() => removeElement(index)"
          >
            <p>
              {{ field.label }}
            </p>
          </el-tag>
        </el-col>
        <!-- <el-col :span="6">
          <el-checkbox
            v-if="
              field.fields_type == 'TABLE_FIELDS' &&
              field.selected_fields.length
            "
            v-model="attached_data_table"
            label="Attach To Table"
            border
            @change="updateIndex"
          ></el-checkbox>
        </el-col> -->
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "templates-formComponents-AggregateFunction",
  components: {},
  props: ["field", "fieldsData"],
  data() {
    return {
      functionTypes: ["Sum", "Average", "Standard Deviation"],
      selectedField: "",
      fields: [],
      repeatableTemplates: [],
      loadingFields: false,
      fields_types: [
        { label: "Individual", value: "INDIVIDUAL" },
        {
          value: "TABLE_FIELDS",
          label: "Table Fields",
        },
      ],
      attached_data_table: false,
    };
  },
  computed: {
    ...mapGetters("companyTemplates", ["getSingleCompanyTemplate"]),
    getEquation() {
      let equation = "";
      this.field.selected_fields.forEach((element) => {
        equation += element.label;
      });
      return equation;
    },
  },
  mounted() {
    this.field["filled_by"] = "SENDER";
    this.repeatableTemplates = this.fieldsData.filter(
      (x) => x.input_type == "ACTION_BUTTON"
    );
    if (this.field.aggregate_self_template) {
      this.fields = this.fieldsData.filter(
        (x) =>
          x.inputType == "NUMBER" ||
          x.inputType == "FORMULA" ||
          x.inputType == "CURRENCY" ||
          x.inputType == "ENTITY_VARIABLE" ||
          x.inputType == 'AGGREGATE_FUNCTION'
      );

      this.fieldsData
        .filter((e) => e.inputType == "DATA_TABLE" || e.inputType == 'ENTITY_TABLE')
        .forEach((e) => {
          let fields = e.data_table_columns.filter(
            (x) =>
              x.inputType == "NUMBER" ||
              x.inputType == "FORMULA" ||
              x.inputType == "CURRENCY" ||
              x.inputType == "ENTITY_VARIABLE" ||
              x.inputType == 'AGGREGATE_FUNCTION'
          );
          fields = fields.map((el) => {
            el.data_table_key = e.key;
            return el;
          });
          this.fields = [...this.fields, ...fields];
        });
    } else {
      this.fetchTemplate();
    }

    if (this.field.fields_type == "TABLE_FIELDS") {
      this.field.repeatable_template = "self";

      if (this.field.selected_fields.length) {
        let field = this.fields.find(
          (e) => this.field.selected_fields[0].key == e.key
        );

        if (field) {
          this.selectedField = field.key;
        }
      }
      this.attached_data_table = this.field.attached_data_table_index > -1;
    }

    // console.log("FIELDS : ", this.repeatableTemplates)
  },
  methods: {
    onChangeFunctionType() {
      console.log("");
    },
    async fetchTemplate() {
      if (this.field.repeatable_template) {
        this.loadingFields = true;
        await this.$store.dispatch(
          "companyTemplates/fetchSingleCompanyTemplate",
          this.field.repeatable_template
        );
        if (this.getSingleCompanyTemplate) {
          this.fields = this.getAvailableFields(
            this.getSingleCompanyTemplate.sections[0].fields
          );

          this.getSingleCompanyTemplate.sections[0].fields
            .filter((e) => e.input_type == "DATA_TABLE")
            .forEach((e) => {
              let fields = this.getAvailableFields(e.data_table_columns);
              this.fields = [...this.fields, ...fields];
            });
        }
        this.loadingFields = false;
      }
    },

    getAvailableFields(fields) {
      return fields.filter((x) => {
        return (
          x.inputype == "NUMBER" ||
          x.inputype == "FORMULA" ||
          x.inputype == "ENTITY_VARIABLE" ||
          x.inputype == "CURRENCY" ||
          x.inputType == 'AGGREGATE_FUNCTION'
        );
      });
    },
    async onChangeTemplate() {
      console.log(
        "this.field.repeatable_template",
        this.field.repeatable_template
      );
      if (this.field.repeatable_template == "self") {
        delete this.field.aggregation_field;
        this.field.aggregate_self_template = true;
        this.fields = this.fieldsData.filter(
          (x) =>
            x.inputType == "NUMBER" ||
            x.inputType == "FORMULA" ||
            x.inputType == "CURRENCY" ||
            x.inputType == "ENTITY_VARIABLE" ||
            x.inputType == 'AGGREGATE_FUNCTION'
        );

        this.fieldsData
          .filter((e) => e.inputType == "DATA_TABLE" || e.inputType == 'ENTITY_TABLE')
          .forEach((e) => {
            let fields = e.data_table_columns.filter(
              (x) =>
                x.inputType == "NUMBER" ||
                x.inputType == "FORMULA" ||
                x.inputType == "CURRENCY" ||
                x.inputType == "ENTITY_VARIABLE" ||
                x.inputType == 'AGGREGATE_FUNCTION'
            );
            this.fields = [...this.fields, ...fields];
          });
      } else {
        this.field.selected_fields = [];
        this.field.aggregate_self_template = false;
        await this.fetchTemplate();
      }
    },
    onSelectField(key) {
      if (this.field.fields_type == "TABLE_FIELDS") {
        let data = this.fields.find((x) => x.key == key);
        this.field.selected_fields = [];
        this.field.selected_fields.push(data);
        this.field.attached_data_table_index = data.data_table_index;
        this.field.attached_data_table_key = data.data_table_key;
      } else {
        this.selectedField = "";
        let data = this.fields.find((x) => x.key == key);
        this.field.selected_fields.push(data);
      }
    },
    removeElement(index) {
      this.field.selected_fields.splice(index, 1);
    },
  },
};
</script>

<style lang="scss"></style>
